import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./pages/home/Home";
import PharmacyCollege from "./pages/Pharmacy/PharmacyCollege";
import About from "./pages/Pharmacy/About/About";
import Courses from "./pages/Pharmacy/Courses/Courses";
import LabFacilities from "./pages/Pharmacy/Lab Facilities/LabFacilities";
import StudentLife from "./pages/Pharmacy/Student Life/StudentLife";
import Pharmaceutics from "./pages/Pharmacy/Lab Facilities/Lab/Pharmaceutics";
import Pharmacology from "./pages/Pharmacy/Lab Facilities/Lab/Pharmacology";
import PharmChemistry from "./pages/Pharmacy/Lab Facilities/Lab/PharmChemistry";
import Pharmacognosy from "./pages/Pharmacy/Lab Facilities/Lab/Pharmacognosy";
import PharmaceuticalAnalysis from "./pages/Pharmacy/Lab Facilities/Lab/PharmaceuticalAnalysis";
import PharmaceuticalBiotechnology from "./pages/Pharmacy/Lab Facilities/Lab/PharmaceuticalBiotechnology";
import ComputerLab from "./pages/Pharmacy/Lab Facilities/Lab/ComputerLab";
import ArtsAndScience from "./pages/ArtsAndScience/ArtsAndScience";

import Nursing from "./pages/Nursing/Nursing";
import NursingPrograms from "./pages/Nursing/Courses/Programs/NursingPrograms";
import Education from "./pages/Education/Education";
import AnnaiPrograms from "./pages/Education/Courses/Programs/AnnaiPrograms";
import Vellamal from "./pages/Vellamal Education/Vellamal";
import VellamalPrograms from "./pages/Vellamal Education/Courses/Programs/VellamalPrograms";
import TreeViewPrograms from "./pages/ArtsAndScience/Courses/Tree View/TreeViewPrograms";
import OverviewCourses from "./pages/ArtsAndScience/Courses/OverviewCourses/OverviewCourses";
import WithLayout from "./pages/layout/WithLayout";
import Vision from "./pages/main/AboutUs/Vision";
import Administration from "./pages/main/AboutUs/Administration";
import FounderHistory from "./pages/main/AboutUs/FounderHistory";
import LifeAnnai from "./pages/main/life@annai/LifeAnnai";
import MileStone from "./components/mileStone/MileStone";
import Campus from "./pages/main/life@annai/categories/Campus";
import Placements from "./pages/main/life@annai/categories/Placements";
import Admission from "./pages/main/Admission/Admission";
import HostelDining from "./pages/main/life@annai/categories/HostelDining";
import SportsGames from "./pages/main/life@annai/categories/SportsGames";
import Internship from "./pages/main/life@annai/categories/Internship";
import EventsParticipation from "./pages/main/life@annai/categories/EventsParticipation";
import CollegeEvents from "./pages/main/life@annai/categories/ColllegeEvents";
import ChairmanDetails from "./pages/main/AboutUs/ChairmanDetails";
import SNA from "./pages/main/life@annai/categories/SNA";
import Payment from "./components/fees-payment/Payment";
function App() {


  return (
    <div className="App">
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <WithLayout>
                <Home />
              
              </WithLayout>
            }
          />{" "}
          <Route
            path="fees-payment"
            element={
              <WithLayout>
                <Payment />
              </WithLayout>
            }
          />
          <Route
            path="/vision"
            element={
              <WithLayout>
                <Vision />
              </WithLayout>
            }
          />
          <Route
            path="/milestone"
            element={
              <WithLayout>
                <MileStone />
              </WithLayout>
            }
          />
          <Route
            path="/administration"
            element={
              <WithLayout>
                <Administration />
              </WithLayout>
            }
          />
          <Route
            path="/chairman-details"
            element={
              <WithLayout>
                <ChairmanDetails />
              </WithLayout>
            }
          />
          <Route
            path="/founder&history"
            element={
              <WithLayout>
                <FounderHistory />
              </WithLayout>
            }
          />
          <Route path="/PharmacyCollege" element={<PharmacyCollege />}>
            <Route path="About" element={<About />} />

            <Route path="Courses" element={<Courses />} />
            <Route path="LabFacilities" element={<LabFacilities />}></Route>
            <Route path="StudentLife" element={<StudentLife />} />
          </Route>
          <Route path="/ArtsAndScience" element={<ArtsAndScience />} />
          <Route
            path="/ArtsAndScience/Overview"
            element={<OverviewCourses />}
          />
          <Route
            path="/ArtsAndScience/Art-and-Humanity/UG-Courses"
            element={<OverviewCourses />}
          />
          {/* <Route
            path="/ArtsAndScience/Art-and-Humanity/PG-Courses"
            element={<PG_CoursesArts />}
          />
          <Route
            path="/ArtsAndScience/Science-Courses/UG-Courses"
            element={<UG_CoursesSci />}
          />
          <Route
            path="/ArtsAndScience/Science-Courses/PG-Courses"
            element={<PG_CoursesSci />}
          /> */}
          <Route path="/Nursing" element={<Nursing />} />
          <Route path="/Nursing/Courses" element={<NursingPrograms />} />
          <Route path="/Education" element={<Education />} />
          <Route path="/Education/Courses" element={<AnnaiPrograms />} />
          <Route path="/Vellamal" element={<Vellamal />} />
          <Route path="/Vellamal/Courses" element={<VellamalPrograms />} />
          <Route
            path="/life@annai"
            element={
              <WithLayout>
                <LifeAnnai />
              </WithLayout>
            }
          />
          <Route
            path="/life@annai/campus"
            element={
              <WithLayout>
                <Campus />
              </WithLayout>
            }
          />
          <Route
            path="/life@annai/placements"
            element={
              <WithLayout>
                <Placements />
              </WithLayout>
            }
          />
          <Route
            path="/life@annai/hostel-dining"
            element={
              <WithLayout>
                <HostelDining />
              </WithLayout>
            }
          />
          <Route
            path="/life@annai/sports-games"
            element={
              <WithLayout>
                <SportsGames />
              </WithLayout>
            }
          />
          <Route
            path="/life@annai/internship"
            element={
              <WithLayout>
                <Internship />
              </WithLayout>
            }
          />
          <Route
            path="/life@annai/EventsParticipation"
            element={
              <WithLayout>
                <EventsParticipation />
              </WithLayout>
            }
          />
          <Route
            path="/life@annai/sna"
            element={
              <WithLayout>
                <SNA />
              </WithLayout>
            }
          />
          <Route
            path="/life@annai/events"
            element={
              <WithLayout>
                <CollegeEvents />
              </WithLayout>
            }
          />
          <Route
            path="/admission"
            element={
              <WithLayout>
                <Admission />
              </WithLayout>
            }
          />
        </Routes>
      </Router>

    </div>
  );
}

export default App;
