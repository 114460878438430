// LifeAnnai.jsx
import React from 'react';
import { Link } from 'react-router-dom'; // Assuming you're using React Router for navigation
import './lifeAnnai.scss'; // Import the corresponding SCSS file for styling

const LifeAnnai = () => {
  return (
    <div className="life-annai-container">
      <h2>Life @ Annai</h2>
      <div className="life-annai-card-container">
        <Link to="/life@annai/campus" className="life-annai-card">
          <span>Campus</span>
        </Link>
        <Link to="/life@annai/placements" className="life-annai-card">
          <span>Placements</span>
        </Link>
        <Link to="/library" className="life-annai-card">
          <span>Library</span>
        </Link>
        <Link to="/life@annai/hostel-dining" className="life-annai-card">
          <span>Hostel and Dining</span>
        </Link>
        <Link to="/life@annai/sports-games" className="life-annai-card">
          <span>Sports and Games</span>
        </Link>
        <Link to="/life@annai/internship" className="life-annai-card">
          <span>Internships</span>
        </Link>
        <Link to="/life@annai/EventsParticipation" className="life-annai-card">
          <span>NSS & YRC</span>
        </Link>
      
        <Link to="/life@annai/sna" className="life-annai-card">
          <span>SNA</span>
        </Link>
        <Link to="/life@annai/events" className="life-annai-card">
          <span>Events</span>
        </Link>
      </div>
    </div>
  );
};

export default LifeAnnai;
