// FounderHistory.js

import React from 'react';
import founder from '../../../assets/home/founder/founder.jpg';
import './founderHistory.scss';

const FounderHistory = () => {
  return (
    <div className="founder-history-container">
      <div className="chairman-message">
        <div className="image">
          <img src={founder} alt="founder" />
        </div>
        <div className="details">
          <div className="name-title">
            <h3>Dr. S. Churchill</h3>
            <h2>Founder & Philanthropist</h2>
          </div>
          <div className="content">
            <h2>Founder's Vision</h2>
            <div className="description">
              <p>
                Annai Educational Institutions was born out of the educational vision of Dr. S. Churchill (1959 - 2023), the first generation entrepreneur and philanthropist.
              </p>
              <p>
                In his childhood, he was nurtured by his father who was a public servant and a teacher which taught him to always serve people in need. He started his first venture in public transportation under the name "SMBS" Sri Murugan Bus Service in 1978. His hardwork and determination gave him tremendous success in the mid 90’s owning 12 private buses serving around lakhs of passengers from Dharmapuri.
              </p>
              <p>
                Dr Churchill founded Annai Matriculation School under Annai Educational and Charitable Trust in 2002 in the vision to enlighten the educationally backward area, at that time. He valued the importance of education and has provided free education for almost ten thousand students till date. Following this, he started various educational institutions like the Teacher Training, Arts and Science, B.Ed, Nursing and Pharmacy.
              </p>
              <p>
                His amazing contribution in the field of education has earned him an Honorary Doctorate by the International Peace University of Germany, to acknowledge his exemplary contribution to education.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FounderHistory;
