import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import "./Courses.scss"; // Import the SCSS file
import NursingPrograms from "./Programs/NursingPrograms";
import BSNProgram from "./Programs/ProgramsList/BSNProgram.jsx";
import ANMProgram from "./Programs/ProgramsList/ANMProgram.jsx";

import ANMImage from "../../../assets/Nursing-clg/anm.png"; // Import your program's image
import BSNImage from "../../../assets/Nursing-clg/BSN.jpg"; // Import your program's image

function Courses() {
  const [selectedProgram, setSelectedProgram] = useState(null);

  const openProgramPopup = (programComponent) => {
    setSelectedProgram(programComponent);
  };

  const closeProgramPopup = () => {
    setSelectedProgram(null);
  };

  const ProgramsList = [
    {
      name: "Bachelor of Science in Nursing",
      component: <BSNProgram />,
      image: BSNImage,
    },
    {
      name: "Auxiliary Nurse and Midwife (ANM)",
      component: <ANMProgram />,
      image: ANMImage,
    },
  ];

  return (
    <div id="courses-nursing" className="courses-wrap-nursing">
      <div className="courses-container1">
        <h2>Courses Offered </h2>
        <p>
          Annai College of Nursing is dedicated to providing a diverse
          range of undergraduate (UG) and Diplamo in  nursing programs, offering
          students the opportunity to achieve their academic and career goals in the field of nursing and healthcare.
        </p>

        <div className="programs-wrap-nursing">
          <div id="nursing-labfacilities" className="programs-nursing">
            <div className="programs-list-nursing">
              {ProgramsList.map((program) => (
                <div
                  className="program-card"
                  key={program.name}
                  onClick={() => openProgramPopup(program.component)}
                >
                  <img src={program.image} alt={program.name} />
                  <h3>{program.name}</h3>
                </div>
              ))}
            </div>
            {/* Render program popup */}
            {selectedProgram && (
              <div className="program-popup">
                <div className="program-popup-content">
                  <span className="close-button" onClick={closeProgramPopup}>
                    &times;
                  </span>
                  {selectedProgram}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Courses;
