import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./navbar.scss";
import annai_web_logo from "../../assets/college_logo/ANNAI web logo.jpg";
import MarqueeDisplay from "../marquee/MarqueeDisplay";
import navhead from "../../assets/nav head 1.svg";
function Navbar() {
  const [aboutUsDropdownOpen, setAboutUsDropdownOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [institutionsDropdownOpen, setInstitutionsDropdownOpen] =
    useState(false); // Set this to true initially
  const [lifeAtAnnaiDropdownOpen, setLifeAtAnnaiDropdownOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const toggleAboutUsDropdown = () => {
    setAboutUsDropdownOpen(!aboutUsDropdownOpen);
    setInstitutionsDropdownOpen(false); // Close the other dropdown
    setLifeAtAnnaiDropdownOpen(false); // Close the Life @ ANNAI dropdown
  };

  const toggleInstitutionsDropdown = () => {
    setInstitutionsDropdownOpen(!institutionsDropdownOpen);
    setAboutUsDropdownOpen(false); // Close the other dropdown
    setLifeAtAnnaiDropdownOpen(false); // Close the Life @ ANNAI dropdown
  };

  const toggleLifeAtAnnaiDropdownOpen = () => {
    setLifeAtAnnaiDropdownOpen(!lifeAtAnnaiDropdownOpen);
    setAboutUsDropdownOpen(false);
    setInstitutionsDropdownOpen(false);
  };
  const closeDropdowns = () => {
    setAboutUsDropdownOpen(false);
    setInstitutionsDropdownOpen(false);
    setLifeAtAnnaiDropdownOpen(false);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  return (
    <div className="home-nav-inst12">
      <section className="contact-info-section">
        <div className="contact-info-container">
          <span className="phone-icon">&#9742;</span>
          <span className="phone-number">+91 94865 77717</span>
          <span className="email-icon">&#9993;</span>
          <span className="email">annaitrustdpi@gmail.com</span>
        </div>
      </section>
      <div className="image-container">
        <img src={navhead} alt="Centered Image" className="centered-image" />
        <div className="menu-items">
          <Link to="/fees-payment">
            <span className="menu-item">Payment</span>
          </Link>
          <span className="separator">|</span>
          <span className="menu-item">Alumni</span>
          <span className="separator">|</span>
          <span className="menu-item">Placement</span>
          <span className="separator">|</span>
          <span className="menu-item">Bus route</span>{" "}
          <span className="separator">|</span>
          <span className="menu-item">Scholarships</span>
          <span className="separator">|</span>
          <span className="menu-item">Contact Us</span>
        </div>
      </div>
      <header className="myNavbar">
        <div className="logo-container">
          <div className="logo-class">
            <img src={annai_web_logo} alt="Logo" className="logo" />
          </div>
        </div>

        <div className="nav-links">
          <ul>
            <li>
              <a href="/">HOME</a>
            </li>

            <li
              className="dropdown"
              onMouseEnter={() => setAboutUsDropdownOpen(true)}
              onMouseLeave={() => setAboutUsDropdownOpen(false)}
            >
              <a>ABOUT US {aboutUsDropdownOpen ? "▴ " : "▾"}</a>
              <div
                className="dropdown-content"
                onMouseEnter={() => setAboutUsDropdownOpen(true)}
                onMouseLeave={() => setAboutUsDropdownOpen(false)}
                style={{display:`${aboutUsDropdownOpen ?  "block":"none"}`,height:"120px"}}
              >
                
                <Link to="/vision" onClick={closeDropdowns}>
                  Mission & Vision
                </Link>
                <Link to="/founder&history" onClick={closeDropdowns}>
                  Founder & History
                </Link>
                <Link to="/milestone" onClick={closeDropdowns}>
                  Milestone
                </Link>
                <Link to="/administration" onClick={closeDropdowns}>
                  Administration
                </Link>
              </div>
            </li>

            <li
              className="dropdown"
              onMouseEnter={() => setInstitutionsDropdownOpen(true)}
              onMouseLeave={() => setInstitutionsDropdownOpen(false)}
            >
              <a>INSTITUTIONS {institutionsDropdownOpen ? "▴ " : "▾"}</a>
              <div
                className={`dropdown-content`}
                onMouseEnter={() => setInstitutionsDropdownOpen(true)}
                onMouseLeave={() => setInstitutionsDropdownOpen(false)}
                style={{display:`${institutionsDropdownOpen ?  "block":"none"}`,height:"130px"}}
              >
                <Link
                  to="/ArtsAndScience"
                  target="_blank"
                  onClick={closeDropdowns}
                >
                  Arts & Science
                </Link>
                <Link
                  to="/PharmacyCollege"
                  target="_blank"
                  onClick={closeDropdowns}
                >
                  Pharmacy
                </Link>
                <Link to="/Nursing" target="_blank" onClick={closeDropdowns}>
                  Nursing
                </Link>
                <Link to="/Education" target="_blank" onClick={closeDropdowns}>
                  Education
                </Link>
                <Link to="/Vellamal" target="_blank" onClick={closeDropdowns}>
                  Velammal
                </Link>
              </div>
            </li>

            <li
              className="dropdown"
              onMouseEnter={() => setLifeAtAnnaiDropdownOpen(true)}
              onMouseLeave={() => setLifeAtAnnaiDropdownOpen(false)}
            >
              <a href="/life@annai">
                LIFE @ ANNAI {lifeAtAnnaiDropdownOpen ? "▴ " : "▾"}
              </a>
              <div
                className={`dropdown-content ${lifeAtAnnaiDropdownOpen ? "show" : ""}`}
                onMouseEnter={() => setLifeAtAnnaiDropdownOpen(true)}
                onMouseLeave={() => setLifeAtAnnaiDropdownOpen(false)}
                style={{display:`${lifeAtAnnaiDropdownOpen ?  "block":"none"}`,height:"220px"}}

              >
                <Link to="/life@annai/campus" onClick={closeDropdowns}>
                  Campus
                </Link>
                <Link to="/life@annai/placements" onClick={closeDropdowns}>
                  Placements
                </Link>
                <Link to="/library" onClick={closeDropdowns}>
                  Library
                </Link>
                <Link to="/life@annai/hostel-dining" onClick={closeDropdowns}>
                  Hostel & Dining
                </Link>{" "}
                <Link to="/life@annai/sports-games" onClick={closeDropdowns}>
                  Sports & Games
                </Link>
                <Link to="/life@annai/internship" onClick={closeDropdowns}>
                  Internships
                </Link>
                <Link
                  to="/life@annai/EventsParticipation"
                  onClick={closeDropdowns}
                >
                  NSS & YRC
                </Link>
                <Link to="/life@annai/sna" onClick={closeDropdowns}>
                  SNA
                </Link>
                <Link to="/life@annai/events" onClick={closeDropdowns}>
                  Events
                </Link>
              </div>
            </li>


            <li>
              <a href="/life@annai/campus">CAMPUS</a>
            </li>
            <li className="admission-button">
              <a href="/admission" className="admission-btn-nav">ADMISSION</a>
            </li>
          </ul>

          <div className="min_navbar">
            {menuOpen && (
              <nav className="sidebar">
                <ul>
                  <li>
                    <a href="/">HOME</a>
                  </li>
                  <li className="dropdown">
                    <a onClick={toggleAboutUsDropdown}>
                      ABOUT US {aboutUsDropdownOpen ? "▴ " : "▾"}
                    </a>
                    <div
                      className={`dropdown-content ${aboutUsDropdownOpen ? "show" : ""}`}
                    >
                      <Link to="/vision" onClick={closeDropdowns}>
                        Mission & Vision
                      </Link>
                      <Link to="/founder&history" onClick={closeDropdowns}>
                        Founder & History
                      </Link>
                      <Link to="/milestone" onClick={closeDropdowns}>
                        Milestone
                      </Link>

                      <Link to="/administration" onClick={closeDropdowns}>
                        Administration
                      </Link>
                    </div>
                  </li>
                  <li className="dropdown">
                    <a onClick={toggleInstitutionsDropdown}>
                      INSTITUTIONS {institutionsDropdownOpen ? "▴ " : "▾"}
                    </a>
                    <div
                      className={`dropdown-content ${institutionsDropdownOpen ? "show" : ""}`}
                    >
                      <Link
                        to="/ArtsAndScience"
                        target="_blank"
                        onClick={closeDropdowns}
                      >
                        Arts & Science
                      </Link>
                      <Link
                        to="/PharmacyCollege"
                        target="_blank"
                        onClick={closeDropdowns}
                      >
                        Pharmacy
                      </Link>
                      <Link
                        to="/Nursing"
                        target="_blank"
                        onClick={closeDropdowns}
                      >
                        Nursing
                      </Link>
                      <Link
                        to="/Education"
                        target="_blank"
                        onClick={closeDropdowns}
                      >
                        Education
                      </Link>
                      <Link
                        to="/Vellamal"
                        target="_blank"
                        onClick={closeDropdowns}
                      >
                        Vellamal
                      </Link>
                    </div>
                  </li>
                  <li className="dropdown">
                    <a onClick={toggleLifeAtAnnaiDropdownOpen}>
                      LIFE @ ANNAI {lifeAtAnnaiDropdownOpen ? "▴ " : "▾"}
                    </a>
                    <div
                      className={`dropdown-content ${lifeAtAnnaiDropdownOpen ? "show" : ""}`}
                    >
                      <Link to="/life@annai/campus">Campus</Link>
                      <Link to="/life@annai/placements">Placements</Link>
                      <Link to="/library">Library</Link>
                      <Link to="/life@annai/hostel-dining">
                        Hostel & Dining
                      </Link>
                      <Link to="/life@annai/sports-games">Sports & Games</Link>
                      <Link to="/life@annai/internship">Internships</Link>
                      <Link to="/life@annai/EventsParticipation">
                        NSS & YRC
                      </Link>
                      <Link to="/life@annai/sna">SNA</Link>
                      <Link to="/life@annai/events">Events</Link>
                    </div>
                  </li>
                  <div className="admission-button">
                    <a
                      onClick={toggleLifeAtAnnaiDropdownOpen}
                      href="/admission"
                    >
                      ADMISSIONS
                    </a>
                  </div>
                </ul>
                {/* Close button for sidebar */}
                <button className="sidebar-close" onClick={toggleMenu}>
                  X
                </button>
              </nav>
            )}
            <div className="burger-icon" onClick={toggleMenu}>
              &#9776;
            </div>
          </div>
        </div>
      </header>
      {/* Secondary Navbar for Mobile View */}
      <div className="mobile-navbar">
        <Link to="/fees-payment">
          <span className="menu-item">Payment</span>
        </Link>
        <span className="separator">|</span>
        <span className="menu-item">Alumni</span>
        <span className="separator">|</span>
       
        <span className="menu-item">Bus route</span>{" "}
        <span className="separator">|</span>
        <span className="menu-item">Scholarships</span>
      
      </div>
      <div className="marquee">{/* <MarqueeDisplay color="white" /> */}</div>
    </div>
  );
}

export default Navbar;
