// TestimonialsCarousel.jsx
import React, { useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './TestimonialsCarousel.scss';
import AOS from "aos";
import "aos/dist/aos.css";
import gallery1 from "../../assets/home/acheivements/gayatri 1.svg";
import gallery2 from "../../assets/home/acheivements/indo-nepal 1.svg";
import gallery3 from "../../assets/home/acheivements/harish 1.svg";

const TestimonialsCarousel = () => {
  const testimonials = [
    {
      label: 'Gayatri',
      image: gallery1,
      description: 'University Ranking in MA Tamil',
    },
    {
      label: 'Bhagavathi, Dhanush, Gagendiran, Utheshwaran',
      image: gallery2,
      description: '200m 4x100m relay - Gold Medal -  Indo Nepal International Championship',
    },
    {
      label: 'Harish',
      image: gallery3,
      description: 'SNA District Event Winner',
    },
  ];
  

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="gtco-testimonials1">
      <h2 data-aos="fade-right"><span>ACHIEVEMENTS </span></h2>
      <OwlCarousel
        className="owl-carousel1 owl-theme"
        loop
        center
        margin={0}
        responsiveClass
        nav={false}
        autoplay
        autoplayTimeout={2000}
        responsive={{
          0: {
            items: 1,
            nav: false,
          },
          680: {
            items: 2,
            nav: false,
            loop: false,
          },
          1000: {
            items: 3, // Display 3 items on larger screens
            nav: true,
          },
        }}
      >
        {testimonials.map((testimonial, index) => (
          <div key={index}>
            <div className="card text-center" data-aos="fade-up">
              <img className="card-img-top" src={testimonial.image} alt="" />
              <div className="card-body">
                <h5>
                  {testimonial.label} <br />
                  <span>{testimonial.description}</span>
                </h5>
              </div>
            </div>
          </div>
        ))}
      </OwlCarousel>
    </div>
  );
};

export default TestimonialsCarousel;
