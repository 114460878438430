import React from 'react';
import "./payment.scss"
import qr_code from "../../assets/home/payment/QR code.jpeg"
// import step1Image from "../../assets/home/payment/Google-Pay-Scanner.jpeg"
import step2Image from "../../assets/home/payment/step 2.jpg"
import step3Image from "../../assets/home/payment/step 3.jpg"
import step4Image from "../../assets/home/payment/step 4.jpg"
import step5Image from "../../assets/home/payment/step 5.jpg"
import annaieducationaltrust_1 from "../../assets/home/payment/QR CODES/annai educational trust  1.jpg"
import annaieducationaltrust_2 from "../../assets/home/payment/QR CODES/annai_educational-trust-2.jpg"
import Nursing_1 from "../../assets/home/payment/QR CODES/Nursing 1.jpg"
const Payment = () => {
  return (
    <div className="payment-container">
      <div className="how-to-pay-container">
        <h2>How to Pay:</h2>
        <div className="step">
          <p><strong>Step 1:</strong> Open Google Pay app on your mobile device.</p>
          {/* <img src={step1Image} alt="Step 1" /> */}
        </div>
        <div className="step">
          <p><strong>Step 2:</strong> Tap on the 'Scan' option.</p>
          <img src={step2Image} alt="Step 2" />
        </div>
        <div className="step">
          <p><strong>Step 3:</strong> Align the QR code within the frame to scan it.</p>
          <img src={step3Image} alt="Step 3" />
        </div>
        <div className="step">
          <p><strong>Step 4:</strong> Enter the amount and confirm the payment.</p>
          <p>Include the following details in the payment note:</p>
          <ul>
            <li>Student Name</li>
            <li>Semester</li>
            <li>College Name</li>
          </ul>
          <img src={step4Image} alt="Step 4" />
        </div>
        <div className="step">
          <p><strong>Step 5:</strong> Payment completed!</p>
          <img src={step5Image} alt="Step 5" />
        </div>
      </div>

      <h1>Fees Payment</h1>

      <div className="qr-codes-container">
        <div className="qr-code-column">
          <img src={annaieducationaltrust_1} alt="QR Code 1" />
          <p>Scan QR Code to pay via Google Pay</p>
        </div>
        <div className="qr-code-column">
          <img src={annaieducationaltrust_2} alt="QR Code 2" />
          <p>Scan QR Code 2 if QR Code 1 is not working</p>
        </div>
        <div className="qr-code-column">
          <img src={Nursing_1} alt="QR Code 3" />
          <p>Scan QR Code 3 if both QR Codes 1 and 2 are not working</p>
        </div>
      </div>

      <div className="bank-details">
        <h2>Accounts Office:</h2>
        <p>Name: Shanmugavel P</p>
        <p>Contact Number: 9789777797</p>
        <p>Email:[Email]</p>

        <p style={{color:"#666",fontSize:"14px", justifyContent:"center"}}>For account transfer  contact Accounts Office</p>
      </div>
    </div>
  );
};

export default Payment;
