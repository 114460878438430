import React from "react";

import "./PharmBanner.scss";
import Carousel from "../../../components/carousel1/Carousel";

import slide1 from "../../../assets/pharmacy/carousel/dep1.png"
import slide2 from "../../../assets/pharmacy/carousel/dep2.jpg"
// import certificate from "../../../assets/certificate.jpg"
function PharmBanner() {
  // const images = [banner, bpharm];
  const images = [slide1, slide2];

  return (
    <div id="pharmbanner" className="banner_wrap">
      <Carousel images={images} />
    </div>
  );
}

export default PharmBanner;
