import React from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import "./NursingFooter.scss"
function NursingFooter() {
  return (
    <div className="NursingFoot">
    <div className="list-container">
      <div className="list">
        <h3>Quick Links</h3>
        <h4>About Us</h4>
        <h4>Carrier</h4>
        <h4>FAQ & Help</h4>
        <h4>Blog</h4>
        <h4>Useful Links</h4>
      </div>
      <div className="social-link">
        <h4>Follow Us On</h4>
        <div className="icon-container">
          <FacebookRoundedIcon />
          <InstagramIcon />
          <TwitterIcon />
        </div>
      </div>

      <div className="list get-in-touch">
        <h3>Get in Touch</h3>
        <div className="contact-info">
          <div className="address">
            <LocationOnIcon />
            <p>
              Salem Main Road, Nambipatti,
              <br />
              Harur, Dharmapuri-636903
            </p>
          </div>
          <div className="phone">
            <PhoneIcon />
            <p>9500605671
</p>
          </div>
          <div className="email">
            <EmailIcon />
            <p>annainursing2013@gmail.com</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}

export default NursingFooter;
