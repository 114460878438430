import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import TreeViewPrograms from "../Tree View/TreeViewPrograms";
import "./OverviewCourses.scss";
import UGProgramsArts from "../ArtsAndHumanity/UG Programs/UGPrograms"; // Import your components
import ArtsNavBar from "../../ArtsNavBar/ArtsNavBar";
import ArtsFooter from "../../Footer/ArtsFooter";
import TopNav from "../../TopNav/TopNav";
import Tooltip from "@mui/material/Tooltip";
import SvgIcon from "@mui/material/SvgIcon";
function OverviewCourses() {
  const [selectedComponent, setSelectedComponent] = useState(null);
  const location = useLocation();
  const selectedCourse = location.state?.selectedComponent;

  useEffect(() => {
    if (selectedCourse) {
      setSelectedComponent(selectedCourse);
      console.log("Selected Course: ", selectedCourse);
    }
  }, [selectedCourse]);

  const renderComponent = (courseName) => {
    console.log(`Clicked on ${courseName}`); // Console log to track button click
    setSelectedComponent(courseName);
  };

  return (
    <div className='course-overview-wrap'>
      <TopNav/>
      <ArtsNavBar/>
      <div className='breadcrumb'>
        <Tooltip title="Home" arrow>
          <Link to="/ArtsAndScience" >
            <SvgIcon style={{ fontSize: 25, color: "black", marginRight: '8px' }}>
              <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
            </SvgIcon>
          </Link>
        </Tooltip>
        <span > / Courses Overview</span>
      </div>
      <h1>Course Overview</h1>
      <div className='content-wrapper'>
        <div className='tree-view'>
          <TreeViewPrograms onComponentSelect={renderComponent} />
        </div>
        <div className='component-placeholder'>
          <div className="grid-container">
            <UGProgramsArts selectedCategory={selectedComponent} /> {/* Pass selectedCategory prop here */}
          </div>
        </div>
      </div>
      <ArtsFooter/>
    </div>
  );
}

export default OverviewCourses;
