import React from "react";
import "./Courses.scss";

function Courses() {
  return (
    <div className="programs-container-pharmacy">
      <div className="program-header">
        <h1>Bachelor of Pharmacy (B.Pharm)</h1>
        <p>
          <span className="regular">REGULAR (4 Years)</span>
          <span className="separator">|</span>
          <span className="lateral">LATERAL ENTRY (3 Years)</span>
        </p>
      </div>
      <div className="program-details">
        <div className="main-container">
          <div className="card large-card">
            <h2>Program Details</h2>
            <ul>
              <li>
                <p>
                  Bachelor of Pharmacy is a four-year program that prepares students for a career in the pharmaceutical industry and research.
                </p>
              </li>
              <li>
                <p>
                  Students are exposed and trained in:
                  <ul>
                    <li>Synthetic and natural chemistry and drugs</li>
                    <li>Formulation development</li>
                    <li>Quality control of drugs and formulations</li>
                    <li>Screening of substances for pharmacological activities</li>
                    <li>Pharmaceutical care process</li>
                  </ul>
                </p>
              </li>
              <li>
                <p>
                  The students undergo focused training in the ‘Practice School’ held at the college and ‘Industrial Training’ at an identified pharmaceutical industry for a specified period of time.
                </p>
              </li>
            </ul>
          </div>
         <div className="card half-card">
  <h2>Eligibility</h2>
  <div className="eligibility-regular">
    <p>
      <strong>Regular:</strong> 
    </p>
    <ul>
      <li>
        Candidate shall have passed the 10+2 examination conducted by the respective state/central government authorities recognized as equivalent to the 10+2 examination by the Association of Indian Universities (AIU) with specific subjects.
      </li>
      <li>
        Any other qualification approved by the Pharmacy Council of India as equivalent to any of the above examinations.
      </li>
      <li> 
        Age limit is 20 years for reserved category & 22 years for the general category.
      </li>
    </ul>
  </div>
  <div className="eligibility-lateral">
    <p>
      <strong>Lateral Entry:</strong> A pass in D. Pharm. course from an institution approved by the Pharmacy Council of India.
    </p>
  </div>
</div>

          <div className="card half-card">
            <h2>Syllabus (From university page)</h2>
            {/* <p>
                <strong>Regular:</strong> Candidate shall have passed the 10+2 examination conducted by the respective state/central government authorities recognized as equivalent to the 10+2 examination by the Association of Indian Universities (AIU) with specific subjects. Any other qualification approved by the Pharmacy Council of India as equivalent to any of the above examinations. Age limit is 20 years for reserved category & 22 years for the general category.
              </p> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Courses;