import React, { useEffect, useState, useRef } from "react";
import "./upcomingEvents.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import LinkWithThumbnail from "../linkWithThumbnail/LinkWithThumbnail";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import './linkWithThumbnail.scss'; // Import your styles here
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
const UpcomingEvents = ({ children }) => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    // Initialize Firebase
    if (!firebase.apps.length) {
      const firebaseConfig = {
        // Your Firebase config details
      };
      firebase.initializeApp(firebaseConfig);
    }

    // Get a reference to the Firestore database
    const db = firebase.firestore();

    // Fetch event details from Firestore
    db.collection("Event")
      .get()
      .then((querySnapshot) => {
        const eventList = [];
        querySnapshot.forEach((doc) => {
          eventList.push(doc.data());
        });
        setEvents(eventList);
      })
      .catch((error) => {
        console.error("Error fetching events: ", error);
      });
  }, []);
  const [links, setLinks] = useState([]);

  useEffect(() => {
    // Fetch links from Firestore when the component mounts
    const firestore = firebase.firestore();
    const linksCollection = firestore.collection("links");

    linksCollection.get().then((querySnapshot) => {
      const linksData = [];
      querySnapshot.forEach((doc) => {
        linksData.push({ id: doc.id, ...doc.data() });
      });
      setLinks(linksData);
    });
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    vertical: true,
    verticalSwiping: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    adaptiveHeight: false,
    autoplay: true, // Enable automatic sliding
    autoplaySpeed: 2000, // Set the slide duration in milliseconds
    afterChange: (currentSlide) => {
      if (currentSlide >= links.length - 3) {
        slider.slickGoTo(0);
      }
    },
  };

  let slider;
  return (
    <div className="upcomingEvents-wrapper">
      <div className="upcomingEvents">
        <div className="upcoming-title">
          <h3 data-aos="fade-right">
            <span> NEWS & EVENTS </span>
          </h3>
        </div>
        <div className="events-wrapper">
          <div className="notepad-bg">
            <div className="scrolling-events-container">
              <div className="list-view">
                <ul className="scrolling-events-list">
                  {events.map((event, index) => (
                    <li key={index}>
                      {event.date} - {event.name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>


          
          <div className="link-with-thumbnail">
            <Slider {...settings} ref={(c) => (slider = c)}>
              {links.map((link) => (
                <div key={link.id} className="link-item">
                  <a href={link.url} target="_blank" rel="noopener noreferrer">
                    <div className="thumbnail">
                      <img src={link.thumbnailSrc} alt="Thumbnail" />
                      <div className="details">
                        <h3>{link.title}</h3>
                        <p>{link.description}</p>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpcomingEvents;
