import React, { useState } from "react";
import "./UGPrograms.scss";
import BAEnglish from "./Program List/BAEnglish";
import English from "../../../../../assets/UG-ARTS/1.webp";
import Tamil from "../../../../../assets/UG-ARTS/2.jpg";
import BComImage from "../../../../../assets/UG-ARTS/3.jpg";
import BAHistoryImage from "../../../../../assets/UG-ARTS/4.jpg";
import MATamilImage from "../../../../../assets/PG-ARTS/1.jpg";
import MAEnglishImage from "../../../../../assets/PG-ARTS/2.jpeg";
import BATamil from "./Program List/BATamil";
import BComComputerApplication from "./Program List/BComComputerApplication";
import BAHistory from "./Program List/BAHistory";
import MATamil from "../PG Programs/Program List/MATamil";
import MAEnglish from "../PG Programs/Program List/MAEnglish";
import BScBotany from "../../ScienceCourses/UG Programs/Program List/BScBotany";
import BScChemistry from "../../ScienceCourses/UG Programs/Program List/BScChemistry";
import MSChemistry from "../../ScienceCourses/PG Programs/Program List/MSChemistry";
import BScPhysics from "../../ScienceCourses/UG Programs/Program List/BScPhysics";
import MSPhysics from "../../ScienceCourses/PG Programs/Program List/MSPhysics";
import MSChemistryImage from "../../../../../assets/PG-SCI/1.jpg";
import MSPhysicsImage from "../../../../../assets/PG-SCI/2.jpg";
import MSMathematicsImage from "../../../../../assets/PG-SCI/3.jpg";
import BotanyImage from "../../../../../assets/UG-SCIENCE/1.jpg";
import ChemistryImage from "../../../../../assets/UG-SCIENCE/2.jpg";
import PhysicsImage from "../../../../../assets/UG-SCIENCE/3.jpg";
import MathematicsImage from "../../../../../assets/UG-SCIENCE/4.jpg";
import ComputerScienceImage from "../../../../../assets/UG-SCIENCE/5.jpeg";
import BScMathematics from "../../ScienceCourses/UG Programs/Program List/BScMathematics";
import MSMathematics from "../../ScienceCourses/PG Programs/Program List/MSMathematics";
import BScComputerScience from "../../ScienceCourses/UG Programs/Program List/BScComputerScience";

function UGPrograms({ selectedCategory }) {
  const [selectedProgram, setSelectedProgram] = useState(null);

  const openProgramsPopup = (programComponent) => {
    setSelectedProgram(programComponent);
  };

  const closeProgramsPopup = () => {
    setSelectedProgram(null);
  };

  const ugProgramsList = [
    {
      category: "English",
      programs: [
        {
          name: "Bachelor of Arts in English",
          component: <BAEnglish />,
          image: English,
        },
        {
          name: "Master of Arts in English",
          component: <MAEnglish />,
          image: MAEnglishImage,
        },
      ],
    },
    {
      category: "Tamil",
      programs: [
        {
          name: "Bachelor of Arts in Tamil",
          component: <BATamil />,
          image: Tamil,
        },
        {
          name: "Master of Arts in Tamil",
          component: <MATamil />,
          image: MATamilImage,
        },
      ],
    },
    {
      category: "History",
      programs: [
        {
          name: "Bachelor of Arts in History",
          component: <BAHistory />,
          image: BAHistoryImage,
        },
      ],
    },
    {
      category: "Commerce",
      programs: [
        {
          name: "Bachelor of Commerce",
          component: <BComComputerApplication />,
          image: BComImage,
        },
      ],
    },
    // Add more categories and programs here if needed
    {
      category: "Botany",
      programs: [
        {
          name: "Bachelor of Science in Botany",
          component: <BScBotany />,
          image: BotanyImage,
        },
      ],
    },
    {
      category: "Chemistry",
      programs: [
        {
          name: "Bachelor of Science in Chemistry",
          component: <BScChemistry />,
          image: ChemistryImage,
        },
        {
          name: "Master of Science in Chemistry",
          component: <MSChemistry />,
          image: MSChemistryImage,
        },
      ],
    },
    {
      category: "Physics",
      programs: [
        {
          name: "Bachelor of Science in Physics",
          component: <BScPhysics />,
          image: PhysicsImage,
        },
        {
          name: "Master of Science in Physics",
          component: <MSPhysics />,
          image: MSPhysicsImage,
        },
  
      ],
    },
    {
      category: "Mathematics",
      programs: [
        {
          name: "Bachelor of Science in Mathematics",
          component: <BScMathematics />,
          image: MathematicsImage,
        },
        {
          name: "Master of Science in Mathematics",
          component: <MSMathematics />,
          image: MSMathematicsImage,
        }
      ],
    },
    {
      category: "Computer Science",
      programs: [
        {
          name: "Bachelor of Science in Computer Sciences",
          component: <BScComputerScience />,
          image: ComputerScienceImage,
        },
  
      ],
    },
  ];

  return (
    <div className="programs-wrap">
      <div id="arts-labfacilities" className="programsArts">
        <div className="programs-List">
          <div className="programs-grid">
            {ugProgramsList.map((category) => (
              <div key={category.category}>
                {category.category === selectedCategory &&
                  category.programs.map((program) => (
                    <div
                      className="programsCard"
                      key={program.name}
                      onClick={() => openProgramsPopup(program.component)}
                    >
                      <img
                        src={program.image}
                        alt={program.name}
                      />
                      <h3>{program.name}</h3>
                    </div>
                  ))}
              </div>
            ))}
          </div>
        </div>
        {selectedProgram && (
          <div className="programsPopup">
            <div className="programsPopupContent">
              <span className="CloseButton" onClick={closeProgramsPopup}>
                &times;
              </span>
              <div className="selectedProgram">
                {selectedProgram}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default UGPrograms;
