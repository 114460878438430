// VisionComponent.jsx
import React from "react";
import logoImage from "../../../assets/college_logo/ANNAI  logo.jpg"; // Import the image for the educational institution's logo
import "./vision.scss";

const Vision = () => {
  return (
    <div className="vision-container">
      <div className="logo-card">
        <img src={logoImage} alt="Logo" className="logo" />
        <div className="logo-content">
          <h2>About Our Logo</h2>
          <p>
            The logo of our educational institution symbolizes our commitment
            to excellence, inclusivity, and innovation. It represents our
            values and aspirations, serving as a beacon of inspiration for our
            students and community.
          </p>
        </div>
      </div>
      <div className="vision-mission-cards">
        <div className="vision card">
          <h2>Vision</h2>
          <p>
            We strive to provide a learning atmosphere for holistic development
            and nurture the young minds by providing quality education which is
            affordable by all.
          </p>
        </div>
        <div className="mission card">
          <h2>Mission</h2>
          <ul>
            <li>
              <span>Quality Education:</span> Providing high-quality
              education accessible to all.
            </li>
            <li>
              <span>Diverse Opportunities:</span> Offering diverse learning
              opportunities for personal and professional growth.
            </li>
            <li>
              <span>Gender Equality:</span> Promoting gender equality and
              inclusivity.
            </li>
            <li>
              <span>Responsibility:</span> Instilling responsibility towards
              self, family, and society.
            </li>
            <li>
              <span>Job Skills:</span> Developing job-ready skills for
              employment.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Vision;
