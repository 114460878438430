// CollegeEvents.js

import React from 'react';
import './CollegeEvents.scss';
import eventImage1 from '../../../../assets/life@annai/events/event1.jpeg'; // Import event image 1
import eventImage2 from '../../../../assets/life@annai/events/event2.jpeg'; // Import event image 2
import eventImage3 from '../../../../assets/life@annai/events/event3.jpeg'; // Import event image 3
import eventImage4 from '../../../../assets/life@annai/events/event4.jpeg'; // Import event image 4
import Carousel2 from '../../../../components/carousel/Carousel2';

const CollegeEvents = () => {
  const eventImages = [eventImage1, eventImage2, eventImage3, eventImage4];

  return (
    <div className="college-events-container">
      <h2 className="section-title">Recent College Events</h2>
      <div className="event-gallery">
        <Carousel2 images={eventImages} />
      </div>
      <div className="event-details">
        <div className="event-info">
          <h3 className="event-title">Title of the Event</h3>
          <p className="event-description">
            Description of the event. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Sed quis lorem et sapien vulputate ultricies. Vivamus aliquam consequat orci, eu
            sollicitudin nulla molestie id.
          </p>
        </div>
        <div className="event-metadata">
          <p className="event-date">Date: January 1, 2024</p>
          <p className="event-location">Location: College Auditorium</p>
        </div>
      </div>
    </div>
  );
};

export default CollegeEvents;
