// TreeViewPrograms.js

import React from "react";
import Box from "@mui/material/Box";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import "./TreeView.scss";

export default function TreeViewPrograms({ onComponentSelect }) {
  const handleCourseClick = (courseName) => {
    onComponentSelect(courseName);
  };

  return (
    <Box className="Box">
      <TreeView
        className="TreeView"
        aria-label="multi-select"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        multiSelect
      >
        <TreeItem nodeId="1" label="Arts and Humanity Courses">
          <TreeItem
            nodeId="2"
            label="English"
            onClick={() => handleCourseClick("English")}
          />
          <TreeItem
            nodeId="3"
            label="Tamil"
            onClick={() => handleCourseClick("Tamil")}
          />
          <TreeItem
            nodeId="4"
            label="History"
            onClick={() => handleCourseClick("History")}
          />
          <TreeItem
            nodeId="5"
            label="Commerce"
            onClick={() => handleCourseClick("Commerce")}
          />
        </TreeItem>
        <TreeItem nodeId="6" label="Science and Technology Courses">
          <TreeItem
            nodeId="7"
            label="Botany"
            onClick={() => handleCourseClick("Botany")}
          />
          <TreeItem
            nodeId="8"
            label="Chemistry"
            onClick={() => handleCourseClick("Chemistry")}
          />
          <TreeItem
            nodeId="9"
            label="Physics"
            onClick={() => handleCourseClick("Physics")}
          />
          <TreeItem
            nodeId="10"
            label="Mathematics"
            onClick={() => handleCourseClick("Mathematics")}
          />
          <TreeItem
            nodeId="11"
            label="Computer Science"
            onClick={() => handleCourseClick("Computer Science")}
          />
        </TreeItem>
      </TreeView>
    </Box>
  );
}
