import React, { useState } from 'react';
import './campus.scss';
import playgroundImage from '../../../../assets/life@annai/campus/playground.jpg';
import libraryImage from '../../../../assets/life@annai/campus/library.jpg';
import gymImage from '../../../../assets/life@annai/campus/gym image.jpg';
import danceFloorImage from '../../../../assets/life@annai/campus/dancefloor.jpg';
import transportImage from '../../../../assets/life@annai/campus/college bus.jpg';
import greenCampusImage from '../../../../assets/life@annai/campus/green campus.jpeg';
import auditoriumImage from '../../../../assets/life@annai/campus/auditorium.jpg';
import hostelDiningImage from '../../../../assets/life@annai/campus/hostel dining.jpeg';
import rainwaterHarvestingImage from '../../../../assets/life@annai/campus/rainwater harvesting.jpg';
import herbalGardenImage from '../../../../assets/life@annai/campus/herbal garden.jpg';

const Campus = () => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const images = [
    playgroundImage,
    libraryImage,
    gymImage,
    danceFloorImage,
    transportImage,
    greenCampusImage,
    auditoriumImage,
    hostelDiningImage,
    rainwaterHarvestingImage,
    herbalGardenImage
  ];
  const campusFacilities = [
    {
      title: 'Playground',
      description: 'Our campus boasts a spacious playground equipped with various sports facilities for students to engage in physical activities and sports events.',
      image: playgroundImage
    },
    {
      title: 'Library',
      description: 'Since teaching and learning at CHRIST is research led, the Library and Information Centres work round the clock. All the campuses have multi-disciplinary libraries well stacked with books and journals. Students can access information on any book or journal article across campuses online. Each student is given a unique identification number for this purpose. The library timing is from morning 8:00 am to 9:00pm in the night. The information of knowledge Centres in each campus and their unique facilities are outlined below:',
      image: libraryImage
    },
    {
      title: 'Gym',
      description: ' The students’ wellbeing both mental and physical is given paramount importance on campus and students can approach counsellors for guidance and comfort for their mental health. All campuses are adequately staffed with wellbeing specialists. Strict professional confidentiality is maintained in transactions between students’ and wellbeing specialists.',
      image: gymImage
    },
    {
      title: 'Dance Floor Studio',
      description: 'Our dance floor studio provides a dedicated space for students to explore their passion for dance and hone their skills under the guidance of experienced instructors.',
      image: danceFloorImage
    },
    {
      title: 'Transport Facilities',
      description: 'We offer reliable transportation services for students and faculty members, ensuring safe and convenient commuting to and from the campus.',
      image: transportImage
    },
    {
      title: 'Green Campus',
      description: 'With lush greenery and well-maintained gardens, our campus provides a serene and eco-friendly environment conducive to learning and personal growth.',
      image: greenCampusImage
    },
    {
      title: 'Auditorium',
      description: 'Our modern auditorium is equipped with advanced audiovisual technology, providing a venue for various academic, cultural, and extracurricular events.',
      image: auditoriumImage
    },
    {
      title: 'Hostel Dining',
      description: 'The University campuses provide secure accommodation to students, both male and female. In the Central Campus, there are separate girls and boys accommodation facilities. There is round the clock security personnel posted at the entrance of each facility and CCTV surveillance in common areas. The terms of residency and etiquette laid out by the hostel wardens are strictly adhered to here. There are restrictions in the number of intake annually and parents and wards are advised to opt for the hostel facility during the admission process itself. There are also safe hostel facilities outside the campus within walking distance from the university.',
      image: hostelDiningImage
    },
    {
      title: 'Rainwater Harvesting',
      description: 'Our campus features rainwater harvesting systems that help conserve water resources and promote sustainability by harnessing rainwater for various purposes.',
      image: rainwaterHarvestingImage
    },
    {
      title: 'Herbal Garden',
      description: 'Our herbal garden showcases a diverse collection of medicinal plants, providing students with hands-on learning opportunities in herbal medicine and alternative therapies.',
      image: herbalGardenImage
    }
  ];

  const openImage = (index) => {
    setSelectedImageIndex(index);
  };

  const closeImage = () => {
    setSelectedImageIndex(null);
  };

  const showNextImage = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  const showPreviousImage = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  return (
    <div className="campus-container">
      {campusFacilities.map((facility, index) => (
        <div className="facility-card" key={index}>
          <h2 className="facility-title">{facility.title}</h2>
          <div className="facility-content">
  <div className="facility-image-container">
    <img src={facility.image} alt={facility.title} className="facility-image" onClick={() => openImage(index)} />
  </div>
  <p className="facility-description">{facility.description}</p>
</div>

        </div>
      ))}
      {selectedImageIndex !== null && (
        <div className="modal">
          <span className="close" onClick={closeImage}>&times;</span>
          <img src={images[selectedImageIndex]} alt={`Image ${selectedImageIndex}`} className="modal-image" />
          <button className="prev" onClick={showPreviousImage}>&#10094;</button>
          <button className="next" onClick={showNextImage}>&#10095;</button>
        </div>
      )}
    </div>
  );
};

export default Campus;
