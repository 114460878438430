// SportsGames.js

import React from 'react';
import './SportsGames.scss';
import sportsImage from '../../../../assets/life@annai/sports-games/sg-1.jpeg'; // Import the sports image
import galleryImage1 from '../../../../assets/life@annai/sports-games/sg-3.jpg'; // Import gallery image 1
import galleryImage2 from '../../../../assets/life@annai/sports-games/sg-4.jpg'; // Import gallery image 2
import galleryImage3 from '../../../../assets/life@annai/sports-games/sg-5.jpg'; // Import gallery image 3
import galleryImage4 from '../../../../assets/life@annai/sports-games/sg-6.jpg'; // Import gallery image 4
import galleryImage5 from '../../../../assets/life@annai/sports-games/sg-7.jpg'; // Import gallery image 5
import Carousel2 from '../../../../components/carousel/Carousel2';

const SportsGames = () => {
  const galleryImages = [
    galleryImage1,
    galleryImage2,
    galleryImage3,
    galleryImage4,
    galleryImage5,
  ];

  return (
    <div className="sports-games-container">
      <div className="sports-games-view">
        <div className="image-container234">
          <img src={sportsImage} alt="Sports" className="sports-image" />
        </div>
        <div className="description-container">
          <h2 className="sports-title">Sports & Games</h2>
          <p className="sports-text">
            Engage in the thrill of competition and the spirit of camaraderie
            with our vibrant sports and games culture. From adrenaline-pumping
            matches to friendly tournaments, our institution offers a diverse
            array of athletic activities to suit every interest and skill
            level. Whether you're a seasoned athlete or a casual participant,
            join us in celebrating the joy of sportsmanship and teamwork.
          </p>
          <a href="https://forms.google.com/" className="google-form-link" target="_blank" rel="noopener noreferrer">Participate in our sports events</a>
        </div>
      </div>
      <div className="gallery">
        <h2>Gallery</h2>
        <Carousel2 images={galleryImages} />
      </div>
    </div>
  );
};

export default SportsGames;
