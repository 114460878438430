import React from 'react';
import './principalMessage.scss'; // Import SCSS file for styling
import principal from "../../../assets/Arst & Science clg/home/principal.JPG";

const PrincipalMessage = () => {
  return (
    <div className="principal-message-container">
      <h1 className="heading">Principal's Message</h1>
      <div className="content">
        <div className="image-container">
          <img src={principal} alt="Principal" />
        </div>
        <div className="message-container">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget nisi aliquet, varius eros id, interdum massa. Integer vel urna a risus consequat tincidunt. Nam efficitur ligula et felis condimentum fringilla.
          </p>
        </div>
      </div>
    </div>
  );
}

export default PrincipalMessage;
