import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Courses.scss";
import { useNavigate } from "react-router-dom";

function Courses() {
  const [isDropdownOpen1, setIsDropdownOpen1] = useState(false);
  const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);
  const navigate = useNavigate();

  const handleDropdownClick1 = () => {
    setIsDropdownOpen1(!isDropdownOpen1);
    setIsDropdownOpen2(false);
  };

  const handleDropdownClick2 = () => {
    setIsDropdownOpen2(!isDropdownOpen2);
    setIsDropdownOpen1(false);
  };

  const handleCourseClick = (courseName) => {
    console.log(`Clicked on ${courseName}`); // Log the clicked course name
    navigate('/ArtsAndScience/Overview', { state: { selectedComponent: courseName } }); // Use navigate to navigate to the Overview component
  };

  return (
    <div id="courses-arts" className="courses-wrap">
      <div className="courses-container">
        <h2>Courses Offered</h2>
        <p>
          Annai College of Arts and Science is dedicated to providing a diverse
          range of undergraduate (UG) and postgraduate (PG) programs, offering
          students the opportunity to achieve their academic and career goals.
          Our institution is nestled in a serene and conducive learning
          environment, fostering quality education and nurturing young minds.
          Here's an overview of the programs we offer:
        </p>

        <div className="horizontal-dropdowns">
          <div className="dropdown">
            <button
              className="nav-link dropdown-toggle"
              onClick={handleDropdownClick1}
            >
              ARTS & HUMANITY COURSES
            </button>
            <ul
              className={`dropdown-menu centered-dropdown-menu ${
                isDropdownOpen1 ? "open" : ""
              }`}
            >
              <li>
                <button onClick={() => handleCourseClick("English")}>
                  English
                </button>
              </li>
              <li>
                <button onClick={() => handleCourseClick("Tamil")}>
                  Tamil
                </button>
              </li>
              <li>
                <button onClick={() => handleCourseClick("History")}>
                  History
                </button>
              </li>
              <li>
                <button onClick={() => handleCourseClick("Commerce")}>
                  Commerce
                </button>
              </li>
            </ul>
          </div>

          <div className="dropdown">
            <button
              className="nav-link dropdown-toggle"
              onClick={handleDropdownClick2}
            >
              SCIENCE & TECHN COURSES
            </button>
            <ul
              className={`dropdown-menu centered-dropdown-menu ${
                isDropdownOpen2 ? "open" : ""
              }`}
            >
              <li>
                <button onClick={() => handleCourseClick("Botany")}>
                  Botany
                </button>
              </li>
              <li>
                <button onClick={() => handleCourseClick("Chemistry")}>
                  Chemistry
                </button>
              </li>
              <li>
                <button onClick={() => handleCourseClick("Physics")}>
                  Physics
                </button>
              </li>
              <li>
                <button onClick={() => handleCourseClick("Mathematics")}>
                  Mathematics
                </button>
              </li>
              <li>
                <button onClick={() => handleCourseClick("Computer Science")}>
                  Computer Science
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Courses;
