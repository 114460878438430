// NumberCount.jsx
import React, { useState, useEffect, useRef } from "react";
import "./numberCount.scss";

const NumberCount = () => {
  const [counts, setCounts] = useState([
    { label: "STUDENTS", value: 0, target: 2350, speed: 10 },
    { label: "STAFFS", value: 0, target: 102, speed: 1 },
    { label: "COURSES", value: 0, target: 30, speed: 1 },
    { label: "CAMPUS ACRES", value: 0, target: 21, speed: 1 },
  ]);

  const [isCounting, setIsCounting] = useState(false);
  const observer = useRef(null);

  useEffect(() => {
    observer.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsCounting(true);
          // Reset counts to 0 when component comes into view again
          setCounts((prevCounts) => {
            return prevCounts.map((count) => ({ ...count, value: 0 }));
          });
        } else {
          setIsCounting(false);
        }
      });
    });

    observer.current.observe(document.querySelector(".numcount-1"));

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    let interval;
    if (isCounting) {
      interval = setInterval(() => {
        setCounts((prevCounts) => {
          return prevCounts.map((count) => {
            if (count.value < count.target) {
              return {
                ...count,
                value:
                  count.value + (count.label === "STUDENTS" ? 10 : count.speed), // Adjust the increment based on the label
              };
            }
            return count;
          });
        });
      }, 30);
    }

    return () => clearInterval(interval);
  }, [isCounting]);

  return (
    <div className="numcount-1">
      <div className="count-background">
        <div className={`number-count-container container-xxl py-5`}>
          <div className="container">
            <div className="row g-4 cont1">
              {counts.map((count, index) => (
                <div
                  key={index}
                  className="col-md-3 wow fadeInDown cont"
                  data-wow-delay={(index + 1) * 0.2 + "s"}
                >
                  <div className="service-item text-center pt-3">
                    <div className="p-1">
                      <div className="value1" akhi={count.target}>
                        {count.value}

                        {count.value === count.target && "+"}
                      </div>
                      <div className="label1">{count.label}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="horizontal-line1"></div>
          <div className="horizontal-line2"></div>
        </div>
      </div>
    </div>
  );
};

export default NumberCount;
