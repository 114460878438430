// Admission.jsx

import React from "react";
import "./admission.scss";
import CampusTour from "../../../components/activities/CampusTour";
import samplevideo from "../../../assets/amsdrone.mp4";

const Admission = () => {
  return (
    <div className="admission-container">
      <AdmissionCard
        title="Why Choose Us?"
        description="We provide a cutting-edge learning environment, offering high-quality education with hands-on experience, industry-aligned curriculum, and extensive support for career growth."
        backgroundColor="#ffd700" // Gold
        textColor="#333" // Black
      />
      <AdmissionCard
        title="Courses Offered"
        description="Explore our diverse range of courses tailored to meet industry demands and individual aspirations."
        backgroundColor="#00bcd4" // Cyan
        textColor="#fff" // White
        showButton // Added prop to show the button
      />
      <AdmissionCard
        title="Admission Form"
        description="Complete the form below to start your journey with us."
        backgroundColor="#f44336" // Red
        textColor="#fff" // White
        link="https://example.com/admission-form" // Link to admission form
      />

      <div className="admission-vid">
        <div className="video-wrapper">
          <video className="vid" width="1100px" height="700px" autoPlay muted>
            <source src={samplevideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  );
};

const AdmissionCard = ({
  title,
  description,
  backgroundColor,
  textColor,
  link,
  showButton,
}) => {
  return (
    <div>
      <div
        className="admission-card"
        style={{ backgroundColor, color: textColor }}
      >
        <h2>{title}</h2>
        <p>{description}</p>
        {showButton && ( // Conditionally render the button
          <a className="courses-button-rem">View Courses</a>
        )}
        {link && (
          <a
            href="https://forms.gle/LZsETeE9xWEQi5tM6"
            className="admission-link"
          >
            Enquire Now
          </a>
        )}
      </div>

      {/* <CampusTour/> */}
    </div>
  );
};

export default Admission;
