import React, { useEffect, useState } from "react";
import NumberCount from "../../components/numberCount/NumberCount";
import ChairmansMessage from "../../components/chairmansMessage/ChairmansMessage";
import "./home.scss";
import CollegeGroup from "../../components/collegeGroup/CollegeGroup";
import Carousel from "../../components/carousel1/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import TestimonialsCarousel from "../../components/topperPerformer/TestimonialsCarousel";
import CampusTour from "../../components/activities/CampusTour";
import UpcomingEvents from "../../components/upcomingEvents/UpcomingEvents";
import slide1 from "../../assets/home/carousel_main/slide_1.jpg"
import slide2 from "../../assets/home/carousel_main/slide_2.jpg"
import slide3 from "../../assets/home/carousel_main/slide_3.png"
import slide4 from "../../assets/home/carousel_main/slide_4.jpg"
import slide5 from "../../assets/home/carousel_main/slide_5.png"
import slide6 from "../../assets/home/carousel_main/slide_6.jpg"
import poster from "../../assets/posters/poster 1 1.svg"
import Popup from "../../components/popUp/Popup"
const Home = () => {
  const images = [slide1, slide2, slide3, slide4, slide5, slide6];
  const [showPopup, setShowPopup] = useState(true); // State variable for controlling popup visibility

  useEffect(() => {
    // Disable scrolling action in the background when the popup is open
    if (showPopup) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    // Cleanup function to restore scrolling when the component unmounts
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [showPopup]);

  const togglePopup = () => {
    setShowPopup(!showPopup); // Toggle popup visibility
  };
  return (
    <div className="home-container">  
      <div className="background-container"></div>
      <div className="home-content">
        <Carousel images={images} />
        <CollegeGroup />
        <NumberCount />
        <ChairmansMessage />
        <UpcomingEvents />
        <CampusTour />
        <TestimonialsCarousel />
      </div>
      {showPopup && <Popup onClose={togglePopup} src={poster} className="popup"  />}

      
    </div>
  );
};

export default Home;
