import React from "react";
import "./mileStone.scss";
import AOS from "aos";
import "aos/dist/aos.css";
function MileStone() {
  return (
    <div className="mileStone-wrapper">
       <div className="mileStone-title">
        <h2><span>
          MILESTONE </span></h2>
      </div>
      <div className="milestones-table">
        <div className="milestones-row">
          <div className="year">2002</div>
          <div className="description">
            Started AMS school from primary to 12th standard
          </div>
        </div>
        <div className="milestones-row">
          <div className="year">2005</div>
          <div className="description">Teacher training institute</div>
        </div>
        <div className="milestones-row">
          <div className="year">2006</div>
          <div className="description">
            College of education was started with an intake of 100 students{" "}
          </div>
        </div>
        <div className="milestones-row">
          <div className="year">2007</div>
          <div className="description">
            Masters programme in education (M.Ed) was started.
          </div>
        </div>
        <div className="milestones-row">
          <div className="year">2009</div>
          <div className="description">
            Vellamal college of education was started as a second institute for
            education.
          </div>
        </div>
        <div className="milestones-row">
          <div className="year">2010</div>
          <div className="description">
            Annai arts and science college started with 5 courses and currently
            we are running with 13+ courses
          </div>
        </div>
        <div className="milestones-row">
          <div className="year">2011</div>
          <div className="description">
            The next venture was into paramedical courses. The first step was
            into B.Sc Nursing which was a 4 years course.
          </div>
        </div>
        <div className="milestones-row">
          <div className="year">2019</div>
          <div className="description">
            ANM (Auxiliary Nurse and Midwife)/village health nurse a 2 years
            diploma course was introduced.
          </div>
        </div>
        <div className="milestones-row">
          <div className="year">2023</div>
          <div className="description">
            Annai college of Pharmacy was opened with an intake of 60 students{" "}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MileStone;
