import React from 'react';
import './placements.scss';
import placementImage from '../../../../assets/life@annai/placements/placement _gallery.jpg'; // Import the image for placements

const Placements = () => {
  return (
    <div className="placements-container">
      <div className="placement-section">
        <img src={placementImage} alt="Placements" className="placement-image" />
        <div className="placement-details">
          <h2 className="placement-title">Placements</h2>
          <p className="placement-description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed quis lorem et sapien
            vulputate ultricies. Vivamus aliquam consequat orci, eu sollicitudin nulla molestie id.
            Phasellus rutrum, leo vel cursus hendrerit, tortor augue facilisis odio, id volutpat
            quam justo sit amet felis.
          </p>
        </div>
      </div>
      <div className="companies-container">
        <div className="company">
          <img src={placementImage} alt="Company 1" className="company-image" />
          <div className="company-details">
            <h3 className="company-title">Company 1</h3>
            <p className="company-description">
              Description of Company 1 Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Sed quis lorem et sapien vulputate ultricies.
            </p>
          </div>
        </div>
        <div className="company">
          <img src={placementImage} alt="Company 2" className="company-image" />
          <div className="company-details">
            <h3 className="company-title">Company 2</h3>
            <p className="company-description">
              Description of Company 2 Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Sed quis lorem et sapien vulputate ultricies.
            </p>
          </div>
        </div> <div className="company">
          <img src={placementImage} alt="Company 2" className="company-image" />
          <div className="company-details">
            <h3 className="company-title">Company 2</h3>
            <p className="company-description">
              Description of Company 2 Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Sed quis lorem et sapien vulputate ultricies.
            </p>
          </div>
        </div> <div className="company">
          <img src={placementImage} alt="Company 2" className="company-image" />
          <div className="company-details">
            <h3 className="company-title">Company 2</h3>
            <p className="company-description">
              Description of Company 2 Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Sed quis lorem et sapien vulputate ultricies.
            </p>
          </div>
        </div> <div className="company">
          <img src={placementImage} alt="Company 2" className="company-image" />
          <div className="company-details">
            <h3 className="company-title">Company 2</h3>
            <p className="company-description">
              Description of Company 2 Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Sed quis lorem et sapien vulputate ultricies.
            </p>
          </div>
        </div> <div className="company">
          <img src={placementImage} alt="Company 2" className="company-image" />
          <div className="company-details">
            <h3 className="company-title">Company 2</h3>
            <p className="company-description">
              Description of Company 2 Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Sed quis lorem et sapien vulputate ultricies.
            </p>
          </div>
        </div>
        {/* Add more companies as needed */}
      </div>
    </div>
  );
};

export default Placements;
