// Internship.js

import React from 'react';
import './Internship.scss';
import internshipImage from '../../../../assets/life@annai/internship/internships.jpg'; // Import the internship image

const Internship = () => {
 return (
    <div className="internship-container">
      <div className="internship-details">
        <h2 className="internship-title">Internship Program</h2>
        <p className="internship-description">
          Our internship program offers hands-on experience and mentorship
          opportunities in various fields. Whether you're interested in
          software development, marketing, design, or any other area, our
          internship program provides a supportive environment for learning and
          growth.
        </p>
        <div className="internship-button-container">
          <a
            href="https://internship-application-form.com"
            className="internship-button"
            target="_blank"
            rel="noopener noreferrer"
          >
            Apply Now
          </a>
        </div>
      </div>
      <div className="internship-image-container">
        <img src={internshipImage} alt="Internship" className="internship-image" />
      </div>
    </div>
  );
};


export default Internship;
