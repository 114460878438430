// FounderHistory.js

import React from 'react';
import chairmanImage from '../../../assets/home/chairman/chairman.jpg';
import './founderHistory.scss';

const ChairmanDetails = () => {
  return (
    <div className="founder-history-container">
      <div className="chairman-message">
        <div className="image">
          <img src={chairmanImage} alt="Chairman" />
        </div>
        <div className="details">
          <div className="name-title">
            {/* <h3>Dr. S. Churchill</h3>
            <h2>Founder & Philanthropist</h2> */}
          </div>
          <div className="content">
            <h2>Chairman Message</h2>
            <div className="description">
              <p>
              As the Chairman of our college, I am deeply committed to fostering an environment that nurtures excellence, innovation, and personal growth. Our college is not just a place of learning; it is a vibrant community where ideas are exchanged, talents are honed, and dreams are realized.
              </p>
              <p>
              You are embarking on an incredible journey of discovery, growth, and transformation. Take advantage of the myriad opportunities available to you, both inside and outside the classroom. Engage with your peers, immerse yourself in your studies, and dare to push the boundaries of your comfort zone.
              </p>
              <p>
              I am immensely proud of what we have accomplished thus far, but I am also excited about the journey ahead. In an ever-changing world, we recognize the importance of staying ahead of the curve, embracing new technologies, and adapting to emerging trends. As we begin this new academic year, let us collectively reaffirm our commitment to academic rigor, inclusivity, and integrity. Let us support and empower one another as we work towards our common goals of academic excellence and student success.
              </p>
              <p>
              Wishing you all a successful and fulfilling academic year ahead.  
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChairmanDetails;
