// EventsParticipation.js

import React from 'react';
import './EventsParticipation.scss';
import nssImage from '../../../../assets/life@annai/nss&yrc/NSS.png'; // Import the NSS image
// import yrcImage from '../../../../assets/events-participation/yrc.jpg'; // Import the YRC image
import Carousel2 from '../../../../components/carousel/Carousel2';

const EventsParticipation = () => {
  const nssGallery = [
    'NSS Event 1',
    'NSS Event 2',
    'NSS Event 3',
    'NSS Event 4',
    'NSS Event 5',
  ];

  const yrcGallery = [
    'YRC Event 1',
    'YRC Event 2',
    'YRC Event 3',
    'YRC Event 4',
    'YRC Event 5',
  ];

  return (
    <div className="events-participation-container">
      <div className="nss-section">
        <h2 className="section-title">NSS Participation</h2>
        <div className="image-container-nss-yrc">
          <img src={nssImage} alt="NSS" className="image" />
        </div>
        <div className="gallery">
          <h3 className="gallery-title">NSS Events</h3>
          <Carousel2 images={nssGallery} />
        </div>
      </div>
      <div className="yrc-section">
        <h2 className="section-title">YRC Participation</h2>
        <div className="image-container-nss-yrc">
          <img src={nssImage} alt="YRC" className="image" />
        </div>
        <div className="gallery">
          <h3 className="gallery-title">YRC Events</h3>
          <Carousel2 images={yrcGallery} />
        </div>
      </div>
    </div>
  );
};

export default EventsParticipation;
