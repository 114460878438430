import React, { useState, useEffect } from "react";
import arts from "../../../assets/arts college.png";
import "bootstrap/dist/css/bootstrap.min.css"; // Make sure you have Bootstrap CSS
import "bootstrap"; // Import Bootstrap JavaScript
import Scrollspy from "react-scrollspy";
import "./ArtsNav.scss";
import UGPrograms from "../Courses/ArtsAndHumanity/UG Programs/UGPrograms";
import MarqueeDisplay from "../../../components/marquee/MarqueeDisplay";
import { Link } from "react-router-dom";

const ArtsNavBar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [activeLink, setActiveLink] = useState("about");

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll(".section");
      sections.forEach((section) => {
        const rect = section.getBoundingClientRect();
        if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
          setActiveLink(section.getAttribute("data-link"));
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="NavBarArts">
      <nav className="navbarArts">
        <div className="logo-container">
          <div className="logo">
            <img src={arts} alt="" height={100} width={100} />
          </div>
          <div className="logo-txt">
            <h1>ANNAI COLLEGE OF ARTS & SCIENCE</h1>
          </div>
        </div>
        <div className={`menu ${isMobileMenuOpen ? "open" : ""}`}>
          <Scrollspy
            items={[
              "about",
              "courses-arts",
              "arts-labfacilities",
              "student-life",
            ]}
            currentClassName="active"
          >
            {/* <Link to="/ArtsAndScience" onClick={() => scrollToSection("about")}>
              HOME
            </Link> */}
            <a href="#about" onClick={() => scrollToSection("about")}>
              ABOUT
            </a>
            <a
              href="#courses-arts"
              onClick={() => scrollToSection("courses-arts")}
            >
              COURSES
            </a>
            <a
              href="#arts-labfacilities"
              onClick={() => scrollToSection("arts-labfacilities")}
            >
              LAB FACILITIES
            </a>
            <a
              href="#student-life"
              onClick={() => scrollToSection("student-life")}
            >
              STUDENT LIFE
            </a>
          </Scrollspy>
        </div>
        <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
          <div className={`burger ${isMobileMenuOpen ? "open" : ""}`}>
            <div className="line1"></div>
            <div className="line2"></div>
            <div className="line3"></div>
          </div>
        </div>
      </nav>
      <div
        id="marquee"
        className="section"
        style={{ backgroundColor: "white" }}
      >
        <MarqueeDisplay
          marqueeText="Affiliated to Periyar University, Salem. Transforming Lives Through Education and Research Excellence."
          color="black"
          className="marquee-display-with-padding"
        />
      </div>
      <div className={`mini-navbar ${isMobileMenuOpen ? "open" : ""}`}>
        <Scrollspy
          items={["about", "courses", "lab-facilities", "student-life"]}
          currentClassName="active"
        >
          <Link to="/ArtsAndScience" onClick={() => scrollToSection("about")}>
              HOME
            </Link>
          <a href="#about" onClick={() => scrollToSection("about")}>
            ABOUT
          </a>
          <a href="#courses" onClick={() => scrollToSection("courses-arts")}>
            COURSES
          </a>
          <a
            href="#lab-facilities"
            onClick={() => scrollToSection("arts-labfacilities")}
          >
            LAB FACILITIES
          </a>
          <a
            href="#student-life"
            onClick={() => scrollToSection("student-life")}
          >
            STUDENT LIFE
          </a>
        </Scrollspy>
      </div>
    </div>
  );
};

export default ArtsNavBar;
