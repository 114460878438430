import React, { useState } from 'react';
import './administration.scss';
import Correspondent from "../../../assets/Administration/Correspondent 2 1.svg";
import chairman from "../../../assets/home/chairman/chairman 3.svg";

import acad_admin from "../../../assets/Administration/academin_admin.jpg";
import nonteach_admin from "../../../assets/Administration/non-teach.jpg";
import { Link } from 'react-router-dom';

const Administration = () => {
  const [selectedCategory, setSelectedCategory] = useState(0);

  const data = [
    {
      category: 'Management Administration',
      description:
        'Meet the leadership team responsible for managing the institution.',
      members: [
        { name: 'Hariharan Churchill', role: 'Chairman',      qualification: ' - B.Des',image:chairman, 

        description: ' ',
        link: '/chairman-details' },
        { name: 'Rythikka  Hariharan  ', role: 'Correspondent',     qualification: '- MSc Counselling Psychology',image: Correspondent},
        // Add more members as needed
      ],
    },
    {
      category: 'Academic Administration',
      description:
        'Learn about the academic leaders shaping the educational experience.',
      members: [
        { name: 'Emily Johnson', role: 'Chairman', image: acad_admin, description: 'Description of Emily Johnson' },
        { name: 'Michael Brown', role: 'Secretary', image: acad_admin, description: 'Description of Michael Brown' },
        // Add more members as needed
      ],
    },
    {
      category: 'Non-Teaching Administration',
      description:
        'Discover the support staff contributing to the institution behind the scenes.',
      members: [
        { name: 'David Wilson', role: 'Department Head', image: nonteach_admin, description: 'Description of David Wilson' },
        { name: 'Sarah Lee', role: 'Assistant', image: nonteach_admin, description: 'Description of Sarah Lee' },
        // Add more members as needed
      ],
    },
  ];

  const handleCategoryClick = (index) => {
    setSelectedCategory(index);
  };

  return (
    <div className="administration-container">
      <div className="categories">
        {data.map((item, index) => (
          <div key={index} onClick={() => handleCategoryClick(index)} className={`category ${selectedCategory === index ? 'active' : ''}`}>
            {item.category}
          </div>
        ))}
      </div>
      <div className="administration-content">
        <h2>{data[selectedCategory].category}</h2>
        <p>{data[selectedCategory].description}</p>
        <div className="members-list">
          {data[selectedCategory].members.map((member, index) => (
            <div key={index} className="member">
              <div className="image-container-administration">
                <img src={member.image} alt={member.name} />
              </div>
              <div className="details">
              <Link to={member.link}> 
                <h3>{member.name} </h3>
                </Link>
                <p>{member.qualification}</p>
                <p>{member.role}</p>
                <p>{member.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Administration;
