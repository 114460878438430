import React from "react";
import "./footer.scss";
import annai_web_logo from "../../assets/college_logo/ANNAI web logo.jpg";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import { motion } from "framer-motion";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
const courseMapping = {
  "Bachelor of Arts in Tamil": " Tamil",
  "Bachelor of Arts in English": " English",
  "Bachelor of Commerce (Computer Application)": "CA",
  "Bachelor of Arts in History": " History",
  "Bachelor of Science in Botany": " Botany",
  "Bachelor of Science in Physics": " Physics",
  "Bachelor of Science in Mathematics": " Mathematics",
  "Bachelor of Science in Computer Sciences": " CS",
  "Bachelor of Education ": "BEd",
  "Bachelor of Science in Nursing": " Nursing",
  "Auxiliary Nurse and Midwife (ANM)": "ANM",
  "Bachelor of Pharmacy (B.Pharm)": "B.Pharm",
  "Master of Science in Chemistry": " Chemistry",
  "Masters of Education": "MEd",
  // Add more courses here
};

const courses = [
  "Bachelor of Science in Nursing",
  "Auxiliary Nurse and Midwife (ANM)",
  "Bachelor of Pharmacy (B.Pharm)",
  "Bachelor of Commerce (Computer Application)",
  "Bachelor of Arts in Tamil",
  "Bachelor of Arts in English",
  "Bachelor of Arts in History",
  "Bachelor of Science in Botany",
  "Bachelor of Science in Physics",
  "Bachelor of Science in Mathematics",
  "Bachelor of Science in Computer Sciences",
  "Bachelor of Education ",

  // "Master of Arts in Tamil",
  // "Master of Arts in English",
  "Master of Science in Chemistry",
  // "Master of Science in Physics",
  // "Master of Science in Mathematics",
  "Masters of Education",
  // Add more courses here
];

// Replace long names with shortened versions
const shortCourses = courses.map((course) => courseMapping[course]);

const courseVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1,
    },
  },
};

const Footer = () => {
  return (
    <div className="footer">
      <div className="Footer-wrapper">
        <div className="centre-div">
          <h4>QUICK LINKS</h4>
          <motion.div
            className="quick-links-container"
            initial="hidden"
            animate="visible"
            variants={{
              visible: {
                transition: {
                  staggerChildren: 0.1,
                },
              },
            }}
          >
            <a href="/admission">
              <motion.p variants={courseVariants}>
                &raquo; Admission procedure
              </motion.p>
            </a>
            <a href="/why-annai">
              <motion.p variants={courseVariants}>&raquo; Why Annai?</motion.p>
            </a>
            <a href="/brochure">
              <motion.p variants={courseVariants}>&raquo; Brochure</motion.p>
            </a>
            <a href="/scholarship">
              <motion.p variants={courseVariants}>
                &raquo; Merit Scholarship
              </motion.p>
            </a>
            <a href="/vision-mission">
              <motion.p variants={courseVariants}>
                &raquo; Vision & Mission
              </motion.p>
            </a>
            <a href="/gallery">
              <motion.p variants={courseVariants}>&raquo; Gallery</motion.p>
            </a>
          </motion.div>
        </div>

        <div className="left-container">
          <h4> COURSES</h4>
          <motion.div
            className="courses-container"
            initial="hidden"
            animate="visible"
            variants={{
              visible: {
                transition: {
                  staggerChildren: 0.1,
                },
              },
            }}
          >
            {shortCourses.map((course, index) => (
              <motion.div
                key={index}
                className="course"
                variants={courseVariants}
              >
                {course}
              </motion.div>
            ))}
          </motion.div>
        </div>

        <div className="right-container">
          <div className="end-headline">
            <h4 className="directions">GET DIRECTION</h4>
            <div className="contact-info">
              <motion.div whileHover={{ scale: 1.1 }}>
                <div className="phone">
                  <PhoneIcon sx={{ fontSize: 30 }} />
                  <a href="tel:+919486577717">+91 94865 77717</a>
                </div>
              </motion.div>
              <motion.div whileHover={{ scale: 1.1 }}>
                <div className="email">
                  <EmailIcon sx={{ fontSize: 30 }} />
                  <a href="mailto:annaitrustdpi@gmail.com">
                    annaitrustdpi@gmail.com
                  </a>
                </div>
              </motion.div>
              <motion.div whileHover={{ scale: 1.1 }}>
                <div className="location">
                  <LocationOnIcon sx={{ fontSize: 30 }} />
                  <a href="#">
                    Salem main road, Nambipatti,
                    <br /> Harur, Dharmapuri Dt, Tamilnadu. Pin: 636-903
                  </a>
                </div>
              </motion.div>

              <div className="logo-container">
                <motion.img
                  whileHover={{ scale: 1.1 }}
                  src={annai_web_logo}
                  alt="Logo"
                  className="logo"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="social-media-container">
          <h4>Follow Us</h4>
          <div className="social-icons">
            <motion.a whileHover={{ scale: 1.2 }} href="#">
              <FacebookIcon />
            </motion.a>
            <motion.a
              whileHover={{ scale: 1.2 }}
              href="https://www.linkedin.com/company/annai-educational-institutions/"
            >
              <LinkedInIcon />
            </motion.a>
            <motion.a
              whileHover={{ scale: 1.2 }}
              href="https://www.instagram.com/annai_colleges?igsh=N2RhYXp0eTB1N3li"
            >
              <InstagramIcon />
            </motion.a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
