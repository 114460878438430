import React from 'react'
import "./NursingBanner.scss"

import Carousel from '../../../components/carousel1/Carousel';
import lib from "../../../assets/Nursing-clg/carousel/stock2.png"
import nurse from "../../../assets/Nursing-clg/carousel/stock1.jpg"
function NursingBanner() {
  const images = [lib, nurse];
  return (
    <div  id="nursingbanner"className='banner_wrap'>
    {/* <img src={banner} alt="" /> */}
    <Carousel images={images} />

  {/* <h3>BANNER</h3> */}
</div>
  )
}

export default NursingBanner
