import React from "react";
import "./topNav.scss";
import SvgIcon from "@mui/material/SvgIcon";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";

const TopNav = () => {
  return (
    <div className="top-nav">
      <ul>
        <li>PAYMENT</li>
        <li>ADMISSION</li>
        <li>CAMPUS</li>
        <li>BUS ROUTE</li>
        <li>SCHOLARSHIP</li>
        <li>
          <Tooltip title="Home" arrow>
            <Link to="/" target="_blank">
              <SvgIcon style={{ fontSize: 25, color: "white" }}>
                <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
              </SvgIcon>
            </Link>
          </Tooltip>
        </li>
      </ul>
    </div>
  );
};

export default TopNav;
