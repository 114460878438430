import React, { useState } from "react";
import "./popup.scss"
const Popup = ({ onClose, src }) => {
  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <button className="close-btn" onClick={onClose}>X</button>
        <img src={src} alt="Promotion Poster" />
      </div>
    </div>
  );
};  

export default Popup;
