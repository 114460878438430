import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import './marqueeDisplay.scss'; // Import your styles here

const   MarqueeDisplay = ({ marqueeText, color }) => {
  const [firebaseText, setFirebaseText] = useState("");

  useEffect(() => {
    if (!marqueeText) {
      const firestore = firebase.firestore();
      const marqueeCollection = firestore.collection('marquees');

      marqueeCollection.orderBy('timestamp', 'desc').limit(1).get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            setFirebaseText(doc.data().text);
          });
        })
        .catch((error) => {
          console.error('Error fetching marquee:', error);
        });
    }
  }, [marqueeText]);

  return (
    <div className="marquee-display" style={{ color: color }}>
      <marquee className="marquee-text" behavior="scroll" direction="left">
        <a>{marqueeText || firebaseText || "No marquee available"}</a>
      </marquee>
    </div>
  );
};

export default MarqueeDisplay;
