import React from 'react';
import SNAImage from '../../../../assets/life@annai/SNA/pic 1.jpeg'; // Import your SNA image here
import './SNA.scss'; // Import SCSS file

const SNA = () => {
  return (
    <div className="sna-container">
      <div className="sna-image-container">
        <img src={SNAImage} alt="SNA" className="sna-image" />
      </div>
      <div className="sna-details">
        <h2 className="sna-title">Student Nurse Association (SNA)</h2>
        <div className="sna-description">
          <p>
            SNA works to provide opportunities for personal, intellectual, professional, and social growth of its members. Much of this is incorporated into health information and services that are provided to the campus and community. Each Nursing student is encouraged to cultivate leadership skills within the SNA.
          </p>
          <p>
            The students undertake community projects such as school health projects, health surveys, nutrition surveys, medical camps, and immunization programs, which are also undertaken by the student nurses. In addition, fundraising activities are also recommended and encouraged.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SNA;
