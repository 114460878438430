import React from "react";
import "./hostelDining.scss";
import hostelDiningImage from "../../../../assets/life@annai/hostel-dining/hostel.jpeg"; // Import the hostel dining image
import galleryImage1 from "../../../../assets/life@annai/hostel-dining/hs-gallery-2.jpeg"; // Import gallery image 1
import galleryImage2 from "../../../../assets/life@annai/hostel-dining/hs-gallery-3.jpeg"; // Import gallery image 2
import galleryImage3 from "../../../../assets/life@annai/hostel-dining/hs-gallery-4.jpeg"; // Import gallery image 3
import Carousel from "../../../../components/carousel/Carousel2"; // Import the Carousel component
const HostelDining = () => {
  const galleryImages = [
    galleryImage1,
    galleryImage2,
    galleryImage3
  ];

  return (
    <div className="hostel-dining-container">
      <div className="hostel-dining-view">
        <div className="image-containerhostel">
          <img src={hostelDiningImage} alt="Hostel Dining" className="hostel-dining-image" />
        </div>
        <div className="description-container">
          <h2 className="title">Hostel & Dining</h2>
          <p className="text">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed quis
            lorem et sapien vulputate ultricies. Vivamus aliquam consequat orci,
            eu sollicitudin nulla molestie id.
          </p>
        </div>
      </div>
      <div className="gallery">
        <h2>Gallery</h2>
        <Carousel images={galleryImages} />

        {/* Include your gallery component here */}
      </div>
    </div>
  );
};

export default HostelDining;
