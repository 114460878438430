import React from "react";
import ANMImage from "../../../../../assets/Nursing-clg/anm.png"; // Import your program's image
// Import your program's image
import "./Nursing_prog.scss"; // Import your program's SCSS file

function ANMProgram() {
  return (
    <div className="programs-container-nursing">
      <div className="programs-header-crs">
        <h2>Auxiliary Nurse and Midwife (ANM)</h2>
        {/* <img src={ANMImage} alt="ANM" /> */}
        </div>
      <div className="program-details">
        <div className="card">
        <h2>Program Details</h2>

        <p>
          Become a skilled healthcare professional with our Auxiliary Nurse and Midwife (ANM) program.
          This 2-year course is designed to prepare you for a fulfilling career in nursing and midwifery.
        </p>
        </div>
        <div className="card">
        <h2>Program Highlights</h2>
        <ul>
          <li>
            <span>Duration:</span> 2 years
          </li>
          <li>
            <span>Eligibility:</span> The minimum age for admission is 17 years.
            10+2 class passed in any group with 45% aggregate.
            The maximum age for admission shall be 35 years.
          </li>
        </ul>
        </div>
        <div className="card">
        <h2>Career Opportunities</h2>
        <p>
          Graduates of the ANM program have various career opportunities in healthcare and midwifery, including:
        </p>
        <ul>
          <li>Staff Nurse</li>
          <li>Community Health Worker</li>
         
          <li>Mid-level Health Care Provider (Govt)</li>
          <li>Maternity Assistant </li>
          <li>Village Health Nurse</li>
        </ul>
      </div>
      </div>
    </div>
  );
}

export default ANMProgram;
