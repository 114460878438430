// CollegeGroup.jsx
import React from "react";
import "./collegeGroup.scss";
import nursing from "../../assets/nursing.png";
import pharmacy from "../../assets/pharmacy.png";
import vellamal from "../../assets/vellamal college.png";
import education from "../../assets/annai college of education.png";
import artsandscience from "../../assets/arts college.png";

function CollegeGroup() {
  return (
    <div className="college-grp1">
      <div className="colleges-row">
        {/* College 1 */}
        <div className="college-item">
          <img
            id="clg1"
            src={artsandscience}
            alt="Arts and Science"
            className="college-logo"
          />
          <h3 className="college-label">ARTS & SCIENCE</h3>
        </div>

        {/* College 2 */}
        <div className="college-item">
          <img
            id="clg2"
            src={nursing}
            alt="College of Nursing"
            className="college-logo"
          />
          <h3 className="college-label">NURSING</h3>
        </div>

        {/* College 3 */}
        <div className="college-item">
          <img
            id="clg3"
            src={pharmacy}
            alt="College of Pharmacy"
            className="college-logo"
          />
          <h3 className="college-label">PHARMACY</h3>
        </div>
      </div>

      <div className="colleges-row">
        {/* College 4 */}
        <div className="college-item">
          <img
            id="clg4"
            src={education}
            alt="College of Education"
            className="college-logo"
          />
          <h3 className="college-label">EDUCATION</h3>
          <p>B.Ed, M.Ed</p>
        </div>

        {/* College 5 */}
        <div className="college-item">
          <img
            id="clg5"
            src={vellamal}
            alt="Vellamal College of Education"
            className="college-logo"
          />
          <h3 className="college-label">VELAMMAL EDUCATION</h3>
          <p>B.Ed</p>
        </div>
      </div>
    </div>
  );
}

export default CollegeGroup;
