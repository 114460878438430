import React from "react";
import "./SocialMediaIcons.scss"; // Assuming you'll create a separate SCSS file for styling
import { ReactComponent as FacebookIcon } from "../../assets/home/social media logos/logos_facebook.svg"; // Import Facebook SVG icon
import { ReactComponent as InstagramIcon } from "../../assets/home/social media logos/skill-icons_instagram.svg"; import { ReactComponent as LinkedInIcon } from "../../assets/home/social media logos/skill-icons_linkedin.svg"; 

const SocialMediaIcons = ({ facebookLink, instagramLink, linkedinLink }) => {
  const handleFacebookClick = () => {
    // Handle Facebook click event, e.g., redirect to Facebook page
    window.location.href = facebookLink;
  };

  const handleInstagramClick = () => {
    // Handle Instagram click event, e.g., redirect to Instagram page
    window.location.href = instagramLink;
  };

  const handleLinkedInClick = () => {
    // Handle LinkedIn click event, e.g., redirect to LinkedIn page
    window.location.href = linkedinLink;
  };

  return (
    <div className="social-media-icons">
      <div className="social-icon facebook" onClick={handleFacebookClick}>
        <FacebookIcon className="icon" />
        <span className="name">Facebook</span>
      </div>
      <div className="social-icon instagram" onClick={handleInstagramClick}>
        <InstagramIcon className="icon" />
        <span className="name">Instagram</span>
      </div>
      <div className="social-icon linkedin" onClick={handleLinkedInClick}>
        <LinkedInIcon className="icon" />
        <span className="name">LinkedIn</span>
      </div>
    </div>
  );
};

export default SocialMediaIcons;