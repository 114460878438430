// WithLayout.jsx
import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import EnquiryButton from "../../components/enquiryButton/EnquiryButton";
import SocialMediaIcons  from "../../components/socialMediaIcons/SocialMediaIcons";


const WithLayout = ({ children }) => {
  return (
    <div>                                                                     
      <Navbar />
      <EnquiryButton />
      <SocialMediaIcons 
        facebookLink="https://www.facebook.com"
        instagramLink="https://www.instagram.com/annai_colleges?igsh=N2RhYXp0eTB1N3li"
        linkedinLink="https://www.linkedin.com/company/annai-educational-institutions/"
      />
      {children}
      <Footer />
    </div>
  );
};

export default WithLayout;
