import React from 'react';
import Slider from 'react-slick';
import "./staffGallery.scss"
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import staff1 from '../../../assets/Arst & Science clg/staff_gallery/1.JPG'; // Import your staff images
import staff2 from '../../../assets/Arst & Science clg/staff_gallery/2.JPG';
import staff3 from'../../../assets/Arst & Science clg/staff_gallery/3.JPG';
import staff4 from '../../../assets/Arst & Science clg/staff_gallery/4.JPG';
import staff5 from '../../../assets/Arst & Science clg/staff_gallery/5.JPG';
import staff6 from '../../../assets/Arst & Science clg/staff_gallery/6.JPG';
import staff7 from '../../../assets/Arst & Science clg/staff_gallery/7.JPG';
import staff8 from '../../../assets/Arst & Science clg/staff_gallery/8.JPG';
import staff9 from '../../../assets/Arst & Science clg/staff_gallery/9.JPG';
import staff10 from '../../../assets/Arst & Science clg/staff_gallery/10.JPG';
import staff11 from '../../../assets/Arst & Science clg/staff_gallery/11.JPG';

const StaffGallery = () => {
  const staffImages = [
    { src: staff1, name: "Staff 1" },
    { src: staff2, name: "Staff 2" },
    { src: staff3, name: "Staff 3" },
    { src: staff4, name: "Staff 4" },
    { src: staff5, name: "Staff 5" },
    { src: staff6, name: "Staff 6" },
    { src: staff7, name: "Staff 7" },
    { src: staff8, name: "Staff 8" },
    { src: staff9, name: "Staff 9" },
    { src: staff10, name: "Staff 10" },
    { src: staff11, name: "Staff 11" }
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    centerMode: true, // Enable center mode
    centerPadding: "0px", // Set padding between center item and side items
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className="staff-gallery-container">
      <h1 className="gallery-heading">Staff Gallery</h1>
      <Slider {...settings}>
        {staffImages.map((staff, index) => (
          <div key={index}>
            <img src={staff.src} alt={staff.name} />
            <p className="image-name">{staff.name}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default StaffGallery;
