// Carousel component (Carousel.jsx)
import React, { useEffect, useState } from "react";
import "./carousel.scss";
import slide6 from "../../assets/home/carousel_main/slide_6.jpg"

const Carousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [transitioning, setTransitioning] = useState(false);
  useEffect(() => {
    const interval = setInterval(goToNext, 5000);
    return () => clearInterval(interval);
  }, [currentIndex]);

  const goToPrevious = () => {
    setTransitioning(true);
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
    setTimeout(() => setTransitioning(false), 500); // Adjust timeout duration to match CSS transition duration
  };

  const goToNext = () => {
    setTransitioning(true);
    const isLastSlide = currentIndex === images.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
    setTimeout(() => setTransitioning(false), 500); // Adjust timeout duration to match CSS transition duration
  };
  const goToSlide = (slideIndex) => {
    setTransitioning(true);
    setCurrentIndex(slideIndex);
    setTimeout(() => setTransitioning(false), 500); // Adjust timeout duration to match CSS transition duration
  };

  // const slideStylesWidthBackground = {
  //   background: 'url("../../assets/home/carousel main/slide 6.jpg")',
  // };

  return (
    <div className="slider-wrapper">
      <div className={`slider-container ${transitioning ? 'transitioning' : ''}`}>
        <div className="arrow left-arrow" onClick={goToPrevious}>
          ❰
        </div>
        <div className="arrow right-arrow" onClick={goToNext}>
          ❱
        </div>
        <div className="myslide"style={{backgroundImage:`url(${images[currentIndex]})`,width:"100%",height:'100%'}}></div>
        {/* <div className="slide" style={{backgroundImage:`url(${"../../assets/home/carousel main/slide 6.jpg"})`}}></div> */}
        <div className="dots-container">
          {images.map((image, index) => (
            <div
              className={`dot ${currentIndex === index ? "active" : ""}`}
              key={index}
              onClick={() => goToSlide(index)}
            >
              ●
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Carousel;
