import React, { useState, useEffect } from 'react';
import pharmacy from '../../../assets/pharmacy.png';
import './PharmNav.scss';
import Scrollspy from 'react-scrollspy';
import MarqueeDisplay from '../../../components/marquee/MarqueeDisplay';

const PharmacyNav = () => {
  const [activeLink, setActiveLink] = useState('about');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('.section');
      sections.forEach((section) => {
        const rect = section.getBoundingClientRect();
        if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
          setActiveLink(section.getAttribute('data-link'));
        }
      });
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className="NavBarPharmacy">
      <nav className="navbarph">
        <div className="logo-container">
          <div className="logo">
            <img src={pharmacy} alt="" height={100} width={100} />
          </div>
          <div className="logo-txt">
            <h1>ANNAI COLLEGE OF PHARMACY</h1>
          </div>
        </div>
        <div className={`menu ${isMobileMenuOpen ? 'open' : ''}`}>
          <Scrollspy items={['about', 'courses', 'lab-facilities', 'student-life']} currentClassName="active">
            <a href="#about">ABOUT</a>
            <a href="#courses">COURSES</a>
            <a href="#lab-facilities">LAB FACILITIES</a>
            <a href="#student-life">STUDENT LIFE</a>
          </Scrollspy>
        </div>
        <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
          <div className={`burger ${isMobileMenuOpen ? 'open' : ''}`}>
            <div className="line1"></div>
            <div className="line2"></div>
            <div className="line3"></div>
          </div>
        </div>
      </nav>
      <div id="marquee" className="section" style={{ backgroundColor: 'white' }}>
        <MarqueeDisplay
          marqueeText="Approved by Pharmacy council of India, New Delhi & Affiliated to The Tamilnadu Dr.M.G.R Medical University, Chennai."
          color="black"
          className="marquee-display-with-padding"
        />
      </div>
      <div className={`mini-navbar ${isMobileMenuOpen ? 'open' : ''}`}>
        <Scrollspy items={['about', 'courses', 'lab-facilities', 'student-life']} currentClassName="active">
          <a href="#about">ABOUT</a>
          <a href="#courses">COURSES</a>
          <a href="#lab-facilities">LAB FACILITIES</a>
          <a href="#student-life">STUDENT LIFE</a>
        </Scrollspy>
      </div>
    </div>
  );
};

export default PharmacyNav;
